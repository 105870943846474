import { FC, useEffect } from 'react';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import ActionEditorBase from '../ActionEditorBase';
import { useTranslation } from 'react-i18next';
import { FormType } from '../../../../models/FormTypes';

const DescriptionEditor: FC<EditorProps> = (props) => {
  const { action, patchAction, form } = props;
  const { t } = useTranslation('form-builder');

  useEffect(() => {
    if (action.noninteractive) {
      return;
    }

    patchAction({
      noninteractive: true,
    });
  }, [action.noninteractive, patchAction]);

  useEffect(() => {
    if (action.visible === undefined) {
      patchAction({ visible: 'true' });
    }
  }, [action.visible, patchAction]);

  return (
    <div data-cy="description-editor">
      <ActionEditorBase
        {...props}
        questionKey="title"
        enableTitleStyle
        altTitle={t('action-properties.description.field-title')}
        altDescription={t('action-properties.description.field-description')}
      ></ActionEditorBase>

      {form.type !== FormType.Quiz && (
        <>
          <ConditionsProperties {...props} />
          <PreviewProperties
            {...props}
            enableFieldSync
            enableDocumentNumbering
            altTitle={t('action-properties.description.preview-title')}
            altDescription={t('action-properties.description.preview-description')}
          />
        </>
      )}
    </div>
  );
};

export default DescriptionEditor;
